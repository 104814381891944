<template>
  <v-container class="container-max-width" fill-height>
    <v-row no-gutters  align="center" justify="center" class="text-md-center text-sm-center text-xs-center">
      <v-card class="elevation-10 px-5 py-5" flat id="page-not-found-404" :class="getIdForBreakpoint()">
        <v-card-text>
          <h1 class="not-found-pae-font font-weight-thin py-3 ma-0">404</h1>
          <p class="title ma-0">Неправильно набрано адресу, або такої сторінки на сайті більше не існує</p>
          <v-btn outlined color="success" class="mt-5 my-3" router :to="{name: 'home'}">Повернутись на головну</v-btn>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
  export default {
      name: "PageNotFound",
      methods: {
          getIdForBreakpoint() {
              if (this.$vuetify.breakpoint.xsOnly) {
                  return 'perspective-xs'
              } else if (this.$vuetify.breakpoint.smOnly)  {
                  return 'perspective-sm card-sm'
              } else {
                  return 'perspective'
              }
          }
      }
  }
</script>

<style scoped lang="scss">
  .container-max-width {
    max-width: 1560px;
  }
  .not-found-pae-font {
    font-size: 10rem !important;
    font-weight: 300 !important;
    line-height: 10rem;
    letter-spacing: -0.015625em !important;
  }

  .card-sm {
    width: 85%;
  }

  .perspective {
    transform: perspective(3000px) rotateY(19deg) rotateX(18deg) rotateZ(-2deg);
  }

  .perspective-sm {
    transform: perspective(1000px) rotateY(4deg) rotateX(9deg) rotateZ(0deg)
  }

  #page-not-found-404 {
    transition: .3s;
    animation-name: container-in;
    animation-delay: .3s;
    animation-direction: normal;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    opacity: 0;
  }

  @keyframes container-in {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.4%;
    }
    75% {
      opacity: 0.7%;
    }
    100% {
      opacity: 1;
    }
  }
</style>
